























































































































































































@import '~variables'

.building-facts
  min-height auto
  height 100%
  .building-facts__center
    z-index 1
    padding-right 640px
    width 100%
    height 100%
    &--large
      padding-right 0
    @media screen and (max-width: 500px), (max-height: 500px)
      overflow hidden
      padding-right 0
  .viewer
    position relative
    padding 0.1px
    height 100%
    background-color $silver
    .tickets-plan-container
      display flex
      flex-direction column
      padding-top $space-2
      width 100%
      height 100%
      .nav-info
        margin 0 $space-2
    .tickets-stats
      position absolute
      top 15px
      left 50%
      margin-left -200px
      width 400px
    .back-button-arrow
      transform rotate(90deg)
    .building-name
      font-weight $fw-bold
  .building-list, .building-viewer
    z-index 1
    height 100%
    @media screen and (max-width: 500px)
      -webkit-overflow-scrolling touch
      -webkit-transform translateZ(0px)
      -webkit-transform translate3d(0, 0, 0)
      -webkit-perspective 1000
  .sidebar-out
    z-index 2
    .ticket-view
      height 100%
      .back-button-arrow
        padding-top 10px
        flex-shrink 0
        margin $space-2
        cursor pointer
        transition all 100ms ease
        transform rotate(90deg)
