





























































































































































































































































@import '~variables'

$grid-border-dark = 1px solid $light

.building-card
  display flex
  flex-basis 160px
  flex-direction column
  justify-content center
  align-items center
  margin 8px 4px
  background-color darken($light, 5%)
  @media screen and (max-width: 500px)
    flex-basis 80px
    margin 0px 7px
  .building-checkbar
    width 100%
  .building-3d
    border-top $grid-border-dark
    cursor pointer
  .checkbar-container
    padding $space-2
  .address-container
    flex 2
    border-right none
  .building__address
    display flex
    justify-content flex-start
    align-items center
    font-size $fs-little
    .configuration-container
      width fit-content
      border-left $grid-border-dark
    .building__configuration
      width fit-content
      cursor pointer
  .building-name
    padding $space-2
    font-weight 700
    font-size $fs-h2
  .button-container
    justify-content center
    padding $space-2
  .create-ticket
    text-transform uppercase
    cursor pointer

.task-count
  .grid-tile
    width 50%
  .grid-tile__label
    margin-bottom $space-1
    text-transform uppercase
  .grid-tile__value
    font-weight 700
    font-size $fs-h2
