@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebar-main .sidebar-title {
  padding: 15px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
  color: #373737;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
}
.sidebar-main .sidebar-title .close-cross {
  top: 10px;
  right: 10px;
  margin-left: auto;
  cursor: pointer;
  transition: all 100ms ease;
}
.sidebar-main .sidebar-title .close-cross:hover {
  transition: all 100ms ease;
  transform: scale(1.3);
}
.sidebar-main .ticket-form {
  overflow-y: auto;
}
/*# sourceMappingURL=src/app/pages/ticket/views/ticket-creation-edition.css.map */