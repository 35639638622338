@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.sidebar-extensible {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  transition: all 0.3s ease;
}
.sidebar-extensible--hidden {
  right: -350px;
}
.sidebar-extensible--small {
  width: 350px;
}
.sidebar-extensible--medium {
  width: calc((100vw / 3));
}
.sidebar-extensible--large {
  width: calc(100vw - 350px);
}
.sidebar-extensible .sidebar-extensible__main {
  flex: 2;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f3f1ef;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #cacaca;
}
::-webkit-scrollbar-thumb {
  background: #828282;
}
::-webkit-scrollbar-thumb:hover {
  background: #000;
}
/*# sourceMappingURL=src/app/components/layout/sidebar/sidebar-extensible.css.map */