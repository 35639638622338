











































































































@import '~variables'

.buildings
  display flex
  justify-content center
  height 100%
  background-color $silver
  @media screen and (max-width: 500px)
    margin-bottom 70px
  .spinner-container
    display flex
    justify-content center
    align-items center
    height 100%
    .spinner
      margin-top 100px
  .buildings-container
    display flex
    align-items center
    overflow-x auto
    height 100%
    &--center
      justify-content center
  .buildings-container--card
    padding $space-3
    @media screen and (max-width: 500px)
      padding 0 0 80px 0

.no-buildings
  display flex
  flex-direction column
  justify-content center
  height 100%
  .no-buildings__message
    padding 15px
    width 100%
    color black
    text-align center
    font-size $fs-h1
  .no-buildings__button
    margin 0 5px
