

















































@import '~variables'

.navigation-item
  padding $space-1
  outline none
  border unset
  border-right $border-light solid $light
  background-color transparent
  font-weight $fw-light
  font-size $fs-h3
  cursor pointer
  &--hover:hover, &--selected
    background-color $light
  &--disabled
    cursor default
  &--left
    margin-left auto
    border-right unset
  &--border-left
    border-left $border-light solid $light
