

















































@import '~variables'

$input-bg-color = $light

.sidebar-main
  display flex
  flex-direction column
  height 100%
  .sidebar-title
    padding 15px
    display inline-flex
    align-items center
    margin-bottom $space-2
    width 100%
    color $font-color
    text-transform uppercase
    font-weight 700
    font-size 2rem
    .close-cross
      top 10px
      right 10px
      margin-left auto
      cursor pointer
      transition all 100ms ease
      &:hover
        transition all 100ms ease
        transform scale(1.3)
  .ticket-form
    overflow-y auto

