







































































@import '~variables'

.nav-info
  border $border-light solid $light
  .navigation-item-list, .info-item-list
    display flex
  .navigation-item-list
    &--separator
      border-bottom $border-light solid $light
