





































































@import '~variables'

.tickets-stats
  display flex
  justify-content space-evenly
  border-radius 30px
  background-color black
  color white
  .stat
    display flex
    flex-direction column
    padding 5px
    width 100%
    border-right solid 1px $dark
    text-align center
    &:last-child
      border-right none
    .label
      text-transform uppercase
      font-weight 600
      font-size $fs-body
      &--new
        color lighten(yellow, 30%)
      &--todo
        color lighten(blue, 60%)
      &--progress
        color lighten(orange, 20%)
      &--done
        color lighten(green, 70%)
    .value
      font-size $fs-h1
