@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.tickets-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tickets-container .sidebar-title {
  display: flex;
  align-items: center;
  padding: 16px;
  color: #373737;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2rem;
}
.tickets-container .create-ticket {
  width: fit-content;
  text-transform: uppercase;
}
.tickets-container .message-display-container {
  margin-top: 64px;
  padding: 32px;
  text-align: center;
  font-weight: 300;
  font-size: 2rem;
}
.tickets-container .tickets-list {
  overflow: auto;
  padding: 0 16px;
  height: 100%;
  text-align: center;
}
.tickets-container .tickets-list .ticket-item:last-child {
  margin-bottom: 32px;
}
.tickets-container .action-container {
  display: flex;
  align-items: center;
  padding: 0 16px 16px;
}
.list-filters {
  margin-left: auto;
}
.list-filters .filter-item {
  text-transform: uppercase;
}
.list-filters button {
  outline: none;
  border: none;
  background: none;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}
.list-filters button:hover {
  text-decoration: underline;
}
.list-filters .isSelected {
  text-decoration: underline;
  font-weight: 900;
}
/*# sourceMappingURL=src/app/pages/ticket/views/sidebar-tickets.css.map */