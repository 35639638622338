@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.floor-checkbar {
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 4px 0;
}
.floor-checkbar .floor-name {
  flex: 2;
  font-size: 1.1rem;
}
.checkbox {
  display: flex;
  margin-right: 16px;
  padding: 3px;
  width: 20px;
  height: 20px;
  background-color: #464749;
  cursor: pointer;
  transform: rotateX(-60deg) rotateY(0deg) rotateZ(-45deg);
}
.checkbox--checked {
  background-color: #fff;
}
/*# sourceMappingURL=src/app/components/floors/floor-checkbar.css.map */