@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.navigation-item {
  padding: 8px;
  outline: none;
  border: unset;
  border-right: 1px solid #f3f1ef;
  background-color: transparent;
  font-weight: 300;
  font-size: 1.1rem;
  cursor: pointer;
}
.navigation-item--hover:hover,
.navigation-item--selected {
  background-color: #f3f1ef;
}
.navigation-item--disabled {
  cursor: default;
}
.navigation-item--left {
  margin-left: auto;
  border-right: unset;
}
.navigation-item--border-left {
  border-left: 1px solid #f3f1ef;
}
/*# sourceMappingURL=src/app/components/layout/nav-info/components/navigation-item.css.map */