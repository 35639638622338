











































































































































































@import '~variables'

$status-border-size = 4px

.ticket-item
  display flex
  flex-direction column
  margin-bottom 8px
  border-radius $border-radius-smooth
  background-color white
  box-shadow $shadow-card
  transition box-shadow 100ms ease
  &:hover
    background-color #ebecf0
  .ticket-row
    display flex
  .ticket-header
    display flex
    align-items flex-start
    padding $space-2
    width 100%
    border-bottom $grid-border
    .ticket-title
      flex 2
      text-align left
      font-weight 400
      font-size $fs-h1
    .chip
      margin-left $space-2
  .ticket-status
    padding $space-2

.chip
  padding 4px
  border-radius 2px
  letter-spacing 0.5px
  line-height 0.9
  &--new
    background-color $warning
    color $dark
