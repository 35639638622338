@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.floors-selection {
  display: flex;
  flex-direction: column;
}
.floors-selection .floors-selection__title {
  margin-bottom: 8px;
  margin-left: 20px;
  padding: 20px 20px 8px 0;
  border-bottom: 1px solid #b4b2a9;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.6rem;
}
.floors-selection .floors-list {
  padding: 20px;
}
.floors-selection .floors-list .floor-item {
  margin-bottom: 2px;
}
.floors-selection .floors-list .floor-item:last-child {
  margin-bottom: 0;
}
/*# sourceMappingURL=src/app/components/floors/floor-selection.css.map */