





































































































@import '~variables'

.tickets-container
  display flex
  flex-direction column
  height 100%
  .sidebar-title
    display flex
    align-items center
    padding $space-2
    color $font-color
    text-transform uppercase
    font-weight 700
    font-size 2rem
  .create-ticket
    width fit-content
    text-transform uppercase
  .message-display-container
    margin-top $space-4
    padding $space-3
    text-align center
    font-weight 300
    font-size $fs-big
  .tickets-list
    overflow auto
    padding 0 $space-2
    height 100%
    text-align center
    .ticket-item:last-child
      margin-bottom $space-3
  .action-container
    display flex
    align-items center
    padding 0 $space-2 $space-2

.list-filters
  margin-left auto
  .filter-item
    text-transform uppercase
  button
    outline none
    border none
    background none
    letter-spacing 0.5px
    font-weight 400
    font-size $fs-body
    cursor pointer
    &:hover
      text-decoration underline
  .isSelected
    text-decoration underline
    font-weight 900
