@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-checkbar {
  position: relative;
  display: flex;
  background-color: #fff;
}
.building-checkbar--selected {
  background-color: #464749;
  color: #fff;
}
.building-checkbar .building-checkbar__status,
.building-checkbar .building-name {
  padding: 4px 0;
}
.building-checkbar .building-checkbar__status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}
.building-checkbar .building-name {
  flex: 3;
}
.building-checkbar .building-checkbar__selection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #000;
  color: #fff;
  font-weight: 400;
  transition: all 0.3s ease;
}
.drawer {
  position: absolute;
  top: -38px;
  left: 105%;
  transition: all 0.3s ease;
}
.check-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ddd9d3;
  transition: all 0.3s ease;
}
.check-circle--selected {
  background-color: #fff;
}
.o-button {
  padding: 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
@media screen and (max-width: 500px) {
  .o-button {
    width: 140px;
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*# sourceMappingURL=src/app/components/buildings/building-card-checkbar.css.map */