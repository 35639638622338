









































@import '~variables'

.floor-checkbar
  position relative
  display flex
  box-sizing border-box
  padding 4px 0
  .floor-name
    flex 2
    font-size $fs-h3
.checkbox
  display flex
  margin-right 16px
  padding 3px
  width 20px
  height @width
  background-color $dark
  cursor pointer
  transform rotateX(-60deg) rotateY(0deg) rotateZ(-45deg)
  &--checked
    background-color white
