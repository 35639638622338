@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.ticket-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(9,30,66,0.31), 0 2px 4px -1px rgba(9,30,66,0.25);
  transition: box-shadow 100ms ease;
}
.ticket-item:hover {
  background-color: #ebecf0;
}
.ticket-item .ticket-row {
  display: flex;
}
.ticket-item .ticket-header {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid #f4f4f4;
}
.ticket-item .ticket-header .ticket-title {
  flex: 2;
  text-align: left;
  font-weight: 400;
  font-size: 1.6rem;
}
.ticket-item .ticket-header .chip {
  margin-left: 16px;
}
.ticket-item .ticket-status {
  padding: 16px;
}
.chip {
  padding: 4px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  line-height: 0.9;
}
.chip--new {
  background-color: #f2c037;
  color: #464749;
}
/*# sourceMappingURL=src/app/pages/ticket/components/ticket-item.css.map */