
































































































@import '~variables'

.floors-selection
  display flex
  flex-direction column
  .floors-selection__title
    margin-bottom 8px
    margin-left 20px
    padding 20px 20px 8px 0
    border-bottom $border-light solid $grey
    text-transform uppercase
    font-weight 400
    font-size $fs-h1
  .floors-list
    padding 20px
    .floor-item
      margin-bottom 2px
      &:last-child
        margin-bottom 0
