@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.tickets-stats {
  display: flex;
  justify-content: space-evenly;
  border-radius: 30px;
  background-color: #000;
  color: #fff;
}
.tickets-stats .stat {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
  border-right: solid 1px #464749;
  text-align: center;
}
.tickets-stats .stat:last-child {
  border-right: none;
}
.tickets-stats .stat .label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
}
.tickets-stats .stat .label--new {
  color: #ffff4d;
}
.tickets-stats .stat .label--todo {
  color: #99f;
}
.tickets-stats .stat .label--progress {
  color: #ffb733;
}
.tickets-stats .stat .label--done {
  color: #8cff8c;
}
.tickets-stats .stat .value {
  font-size: 1.6rem;
}
/*# sourceMappingURL=src/app/pages/ticket/components/tickets-stats.css.map */