@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-card {
  display: flex;
  flex-basis: 160px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 4px;
  background-color: #e9e5e1;
}
@media screen and (max-width: 500px) {
  .building-card {
    flex-basis: 80px;
    margin: 0px 7px;
  }
}
.building-card .building-checkbar {
  width: 100%;
}
.building-card .building-3d {
  border-top: 1px solid #f3f1ef;
  cursor: pointer;
}
.building-card .checkbar-container {
  padding: 16px;
}
.building-card .address-container {
  flex: 2;
  border-right: none;
}
.building-card .building__address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
}
.building-card .building__address .configuration-container {
  width: fit-content;
  border-left: 1px solid #f3f1ef;
}
.building-card .building__address .building__configuration {
  width: fit-content;
  cursor: pointer;
}
.building-card .building-name {
  padding: 16px;
  font-weight: 700;
  font-size: 1.3rem;
}
.building-card .button-container {
  justify-content: center;
  padding: 16px;
}
.building-card .create-ticket {
  text-transform: uppercase;
  cursor: pointer;
}
.task-count .grid-tile {
  width: 50%;
}
.task-count .grid-tile__label {
  margin-bottom: 8px;
  text-transform: uppercase;
}
.task-count .grid-tile__value {
  font-weight: 700;
  font-size: 1.3rem;
}
/*# sourceMappingURL=src/app/components/buildings/building-card.css.map */