@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.building-facts {
  min-height: auto;
  height: 100%;
}
.building-facts .building-facts__center {
  z-index: 1;
  padding-right: 640px;
  width: 100%;
  height: 100%;
}
.building-facts .building-facts__center--large {
  padding-right: 0;
}
@media screen and (max-width: 500px), (max-height: 500px) {
  .building-facts .building-facts__center {
    overflow: hidden;
    padding-right: 0;
  }
}
.building-facts .viewer {
  position: relative;
  padding: 0.1px;
  height: 100%;
  background-color: #ddd9d3;
}
.building-facts .viewer .tickets-plan-container {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  width: 100%;
  height: 100%;
}
.building-facts .viewer .tickets-plan-container .nav-info {
  margin: 0 16px;
}
.building-facts .viewer .tickets-stats {
  position: absolute;
  top: 15px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
}
.building-facts .viewer .back-button-arrow {
  transform: rotate(90deg);
}
.building-facts .viewer .building-name {
  font-weight: 700;
}
.building-facts .building-list,
.building-facts .building-viewer {
  z-index: 1;
  height: 100%;
}
@media screen and (max-width: 500px) {
  .building-facts .building-list,
  .building-facts .building-viewer {
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000;
  }
}
.building-facts .sidebar-out {
  z-index: 2;
}
.building-facts .sidebar-out .ticket-view {
  height: 100%;
}
.building-facts .sidebar-out .ticket-view .back-button-arrow {
  padding-top: 10px;
  flex-shrink: 0;
  margin: 16px;
  cursor: pointer;
  transition: all 100ms ease;
  transform: rotate(90deg);
}
/*# sourceMappingURL=src/app/pages/ticket/index.css.map */