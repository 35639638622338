@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/Lato/Light/Lato-Light.ttf") format('truetype'), url("/fonts/Lato/Light/Lato-Light.woff2") format('woff2'), url("/fonts/Lato/Light/Lato-Light.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Lato/Regular/Lato-Regular.ttf") format('truetype'), url("/fonts/Lato/Regular/Lato-Regular.woff2") format('woff2'), url("/fonts/Lato/Regular/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Lato/Bold/Lato-Bold.ttf") format('truetype'), url("/fonts/Lato/Bold/Lato-Bold.woff2") format('woff2'), url("/fonts/Lato/Bold/Lato-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/Lato/Black/Lato-Black.ttf") format('truetype'), url("/fonts/Lato/Black/Lato-Black.woff2") format('woff2'), url("/fonts/Lato/Black/Lato-Black.woff") format('woff');
}
.buildings {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: #ddd9d3;
}
@media screen and (max-width: 500px) {
  .buildings {
    margin-bottom: 70px;
  }
}
.buildings .spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.buildings .spinner-container .spinner {
  margin-top: 100px;
}
.buildings .buildings-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  height: 100%;
}
.buildings .buildings-container--center {
  justify-content: center;
}
.buildings .buildings-container--card {
  padding: 32px;
}
@media screen and (max-width: 500px) {
  .buildings .buildings-container--card {
    padding: 0 0 80px 0;
  }
}
.no-buildings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.no-buildings .no-buildings__message {
  padding: 15px;
  width: 100%;
  color: #000;
  text-align: center;
  font-size: 1.6rem;
}
.no-buildings .no-buildings__button {
  margin: 0 5px;
}
/*# sourceMappingURL=src/app/components/buildings/buildings-list.css.map */